import React from 'react';

import Seo from '../layout/SEO';

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    <h2 class="title is-2 is-family-secondary">Getting Started</h2>
    <ul>
      <li>
        To learn more about Subscribe Pro's subscription commerce solution, you
        can start with our{' '}
        <a href="/users/introduction/">Introduction to Subscribe Pro</a>.
      </li>
      <li>
        Need help implementing Subscribe Pro? Find the{' '}
        <a href="/integrations/">eCommerce platform integrations</a> for your
        eCommerce platform and locate the{' '}
        <strong>
          <em>Installation Guide</em>
        </strong>
        . We have pre-built integrations with <strong>Magento</strong>,{' '}
        <strong>Salesforce Commerce Cloud (SFCC)</strong>, and others.
      </li>
      <li>
        Search for anything with our powerful <strong>Search</strong> box above!
      </li>
      <li>
        Need more help? Find details about our{' '}
        <a
          href="https://www.subscribepro.com/support/"
          target="_blank"
          rel="noopener noreferrer"
        >
          individualized support offerings here
        </a>
        .
      </li>
    </ul>
    <h2 class="title is-2 is-family-secondary">Understanding our Platform</h2>
    <p>
      We've built the most{' '}
      <strong>
        <em>advanced subscription commerce platform</em>
      </strong>{' '}
      around for brands who sell{' '}
      <strong>
        <em>physical goods</em>
      </strong>
      . Our platform provides a{' '}
      <strong>
        <em>stable foundation</em>
      </strong>{' '}
      upon which brands can build highly-customized subscription offerings.
      Offerings that create and retain loyal customers.
    </p>
    <ul>
      <li>
        Visit our extensive documentation on{' '}
        <a href="/users/ordering-and-automation/">
          Subscription Ordering and Automation
        </a>{' '}
        to learn how our subscription commerce engine works.
      </li>
      <li>
        Get an <a href="/users/introduction/">Introduction to Subscribe Pro</a>{' '}
        in general
      </li>
      <li>
        Learn about{' '}
        <a href="/users/">
          Configuration, Testing, Transactional Emails and More
        </a>
      </li>
    </ul>
    <h2 class="title is-2 is-family-secondary">Not a Developer?</h2>

    <p>
      We'd love to speak with you about Subscribe Pro or introduce you to one of
      our partners. Shoot us an email (
      <a
        href="mailto:info@subscribepro.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@subscribepro.com
      </a>
      ) or{' '}
      <a
        href="https://www.subscribepro.com/contact-sales/"
        target="_blank"
        rel="noopener noreferrer"
      >
        reach out here
      </a>
      .
    </p>
  </>
);

export default NotFoundPage;
